export const LOADERS = [
  ["Éveil des saveurs"],
  ["Préparation du tajine"],
  ["Mélange des épices"],
  ["Cuisson lente du couscous"],
  ["Grillade de brochettes"],
  ["Infusion de thé à la menthe"],
  ["Sculpture de légumes"],
  ["Préparation des mezzés"],
  ["Dressage des plats"],
  ["Soin apporté à chaque détail"],
  ["Création d’un festin marocain"],
  ["Arômes qui embaument l’air"],
  ["Chaleur des épices"],
  ["Ébullition des idées culinaires"],
  ["Finitions des recettes familiales"],
  ["Passion dans chaque plat"],
  ["Héritage culinaire en action"],
  ["Cuisson du pain maison"],
  ["Fraîcheur des ingrédients"],
  ["Soleil dans chaque bouchée"],
  ["Rôtissage parfait des viandes"],
  ["Feu sous le tajine"],
  ["Tournée des épices marocaines"],
  ["Marinade des saveurs"],
  ["Table prête à vous accueillir"],
  ["Préparation de l’expérience Ryad Naji"],
  ["Cuisson du méchoui"],
  ["Délicatesse du service"],
  ["Finalisation du menu"],
  ["Légumes soigneusement coupés"],
  ["Harmonie des goûts"],
  ["Apothéose culinaire imminente"],
  ["Table dressée avec amour"],
  ["Cuisson à feu doux"],
  ["Derniers ajustements"],
  ["Touche finale sur le plat"],
  ["Senteurs d’ailleurs"],
  ["Symphonie de saveurs en préparation"],
  ["Création de moments inoubliables"],
  ["Attente gourmande"],
  ["Service de plats d’exception"],
  ["Effusion de parfums"],
  ["Dégustation des saveurs traditionnelles"],
  ["Savoir-faire marocain à l’œuvre"],
  ["Célébration des goûts"],
  ["Partage autour de la table"],
  ["Saveurs authentiques en route"],
  ["Délice prêt à être servi"],
  ["Recette familiale en préparation"],
  ["Arômes de la cuisine marocaine"],
  ["Derniers ajustements avant la dégustation"],
  ["Service à la perfection"],
  ["Saveurs prêtes à être découvertes"],
  ["Fraîcheur et tradition"],
  ["Secrets de cuisine révélés"],
  ["Création de souvenirs culinaires"],
  ["Invitation au voyage gustatif"],
  ["Tradition marocaine dans chaque bouchée"],
  ["Assiette pleine de passion"],
  ["Explosion de saveurs en approche"],
  ["Accords parfaits de saveurs marocaines"],
  ["Célébration de l’art culinaire"],
  ["Fusion des épices et des arômes"],
  ["Plat prêt à être savouré"],
  ["L’histoire d’un plat marocain"],
  ["Art de la table au rendez-vous"],
  ["À la découverte des saveurs uniques"],
  ["Service dans un instant"],
  ["Un voyage culinaire s’annonce"],
  ["Préparation du festin Ryad Naji"],
  ["Les saveurs sont en route"],
  ["Bientôt prêt à servir"],
];
